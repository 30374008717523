/**
 * @file Main
 * @author Simon Anderson
 */
/* eslint-disable unicorn/prefer-top-level-await */

// DevTools is a side effect import, imports nothing for production.
// eslint-disable-next-line import/no-unassigned-import
import './main-devtools';

import { ensureLoadScript } from '~/framework/network/script-loader/load-script';

// Globals
window.AppGlobals = window.AppGlobals || {};
window.CdnFallbacks = window.CdnFallbacks || [];
window.AppHooks = window.AppHooks || {
	initialise: [],
	beforeMount: [],
	afterMount: [],
	ready: [],
};

// Run
if (!window.App) {
	const ensureCdnPromises = window.CdnFallbacks.map(async (fallback) =>
		ensureLoadScript(fallback.src, fallback.global)
	);

	Promise.all(ensureCdnPromises)
		.then(async () => import(/* webpackChunkName: "app" */ './app'))
		.then(async ({ App }) => new App().autoStart())
		.catch(console.error);
}
